import React, {useState, useEffect} from "react";
import styled,  {keyframes}  from "styled-components";
import { BigScaleElements, MediumScaleElements, SmallScaleElements } from  '../../../images/entdecken/closeup/scaleElements'

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;
const Wrapper = styled.div`
  position: absolute;
  top:0;
  height:100vh;
  width:100vw;
  z-index: 15;
  pointer-events: none;
  opacity:${props => props.show ? 0 : 1 }; 
`

const ScaleElem = styled.img`
  position: absolute;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  width:${props => props.width}px;
`

const BigElement = styled(ScaleElem)`
  bottom:0;
  right: 100px; 
  transform: rotate(-40deg);
`

const MediumElement = styled(ScaleElem)`
  top:250px;
  left: 250px; 
  transform: rotate(40deg);
`

const SmallElement = styled(ScaleElem)`
  bottom:35%;
  right: 30%; 
  transform: rotate(-40deg);
`




const ScaleElement = ({show, pxWidth = 0, pxHeight = 0}) =>  {
    const [randomElement, setRandomElement] = useState(null)
    const [toggleAnimation, setToggleAnimation] = useState(true)
    
    useEffect(() => {
      const allElements = [SmallScaleElements, MediumScaleElements, BigScaleElements]
      const getRandomInt = (max) =>  Math.floor(Math.random() * max);
      setToggleAnimation(!toggleAnimation) // this changes key in component and forces a rerender to trigger animation
      const calcPixelWidth = (widthMM) => {
        const pixelPerCm = 40
        const widthCM= parseInt(widthMM)/10
        return widthCM * pixelPerCm
      }
      
      const getScaleElemSize = (pxWidth, pxHeight) => {
        const biggestWidth = 1920;
        const biggestHeight = 1080;
        const isSmallSize = () =>  pxWidth < biggestWidth * 0.33 || pxHeight < biggestHeight * 0.33
        const isMediumSize = () => (pxWidth > biggestWidth*0.33 && pxWidth < biggestWidth*  0.66) || (pxHeight > biggestHeight*0.33 && pxHeight < biggestHeight * 0.66)
        const isBigSize = () => pxWidth > biggestWidth*0.66  || pxHeight > biggestHeight*0.66
        if(isSmallSize()) return 0
        if(isMediumSize()) return 1
        if(isBigSize()) return 2
      }
      const size = getScaleElemSize(pxWidth, pxHeight)
      const chosenArray = allElements[size]
      const elementIndex = getRandomInt(chosenArray.length)
      const selectedElem = chosenArray[elementIndex]
      const widthPx = calcPixelWidth(selectedElem.widthMM)
      setRandomElement({size,widthPx,...selectedElem})
    },[pxWidth, pxHeight])
   

    return (
        <Wrapper show={show}>
          {randomElement?.size === 0 &&
            <SmallElement key={toggleAnimation} src={randomElement.img} width={randomElement.widthPx} ></SmallElement>
          }
          {randomElement?.size === 1 &&
            <MediumElement key={toggleAnimation} src={randomElement.img} width={randomElement.widthPx}></MediumElement>
          }
          {randomElement?.size === 2 &&
            <BigElement key={toggleAnimation} src={randomElement.img} width={randomElement.widthPx}></BigElement>
          }
        </Wrapper>
    )
}


export default ScaleElement