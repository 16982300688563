import React, {useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import arrowIcon from '../../../images/icons/generieren/arrow.svg'
import SwiperCore, { Navigation, Virtual } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {croppedPatternImageUrl} from "../../../data/dataUrls"
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Navigation, Virtual]);


const Wrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: space-around;
  align-items: center;
  user-select: none;
`;

const FrameControl = styled.div`
  width: 17.5%;
  height: 100%;
  z-index: 25;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color:white;
  overflow:visible;
`;

const PrevFrames = styled(FrameControl)`
  box-shadow: 10px 0px 20px #00000026;
  left:0px;
  cursor:pointer;
  display:flex;
  justify-content: center;
`;

const NextFrames = styled(FrameControl)`
  box-shadow: -10px 0px 20px #00000026;
  right:0px;
  cursor:pointer;
`;

const PatternThumb = styled.img`
  height: 50px;
  width: 50px;
  background-color: black;
  cursor:pointer;
  &:hover{
    border:2px solid white;
  }
`;

const PrevArrow = styled.img`
  position: absolute;
  height: 20px;
  right: 20px;
  &:hover{
    transform: scale(1.2);  
  }
  transition:all 0.05s ease-in-out;
`;
  
const NextArrow = styled.img`
  position: absolute;
  left: 20px;
  height: 20px;
  transform: rotate(-180deg);
  &:hover{
    transform: rotate(-180deg) scale(1.2);
    transition:all 0.05s ease-in-out;
  }
`;

const PrevText = styled.div`
  text-transform: uppercase;
  padding-right:20px;
`

const Marker = styled.div`
  z-index:10;
  position:absolute;
  border:2px solid black;
  height:60px;
  width:60px;
  left:50%;
  transform: translateX(-50%);
  pointer-events: none;
`
const PatternWrapper = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
`

const PatternCarousel = ({annotationData, onThumbClick, onIndexChange, activeMediaId}) => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef =useRef(null)
  const swiperRef =useRef(null)
  const [slidesPerView, setSlidesPerView ] = useState(5)
  const { width } = useWindowDimensions();

  useEffect(() => {
    /* Get initial number of slides */
    if(typeof(window) !== 'undefined'){   
      setSlidesPerView((width )/70)
    }

  },[])
  

  useEffect(() => {
    const updateSwiperIndex = () => {
      if(swiperRef?.current && annotationData?.length > 0){
        const index = annotationData.findIndex((pattern) => pattern.mediaID === activeMediaId)
        swiperRef.current?.swiper.slideTo(index)
      }
    }
    updateSwiperIndex()
  },[activeMediaId,annotationData])


  return (
    <Wrapper>
      <PrevFrames ref={navigationPrevRef}>
        <PrevText> 
        <p style={{fontWeight:700,}}>Weitere Muster</p>
        <p>aus dem Archiv</p>
         </PrevText>
       
        <PrevArrow src={arrowIcon}></PrevArrow>
      </PrevFrames> 
      <Marker></Marker>
      <Swiper
        virtual={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        slidesPerView={slidesPerView}
        onResize={(swiper) =>{setSlidesPerView(swiper.width/70)}} // updates slidesPerView i a responsive way
        freeMode={false}
        onRealIndexChange={(e) => onIndexChange(e.activeIndex)}
        centeredSlides={true}
        spaceBetween={20}
        style={{width:"100%",height:"50px"}}
        ref={swiperRef}
      >
        {annotationData !== "" && annotationData.map((entryPoint) => (
          <SwiperSlide style={{ width: "auto"}} virtualIndex={entryPoint.mediaID} key={`thumb-${entryPoint.mediaID}`}>
            <PatternWrapper>
            <PatternThumb
              src={`${croppedPatternImageUrl}64/${entryPoint.mediaID}`}
              onClick={() => onThumbClick(entryPoint)}
              
            />
            </PatternWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <NextFrames ref={navigationNextRef}>
        <NextArrow src={arrowIcon}></NextArrow>
      </NextFrames>
    </Wrapper>
  )
}

export default PatternCarousel