
import bueroklammer from './scale-objects_0010_bueroklammer-@35mm.png'
import gluehbirne from './scale-objects_0015_gluehbirne-@125mm.png'
import karte from './scale-objects_0003_karte-@88mm.png'
import kasette from './scale-objects_0002_kasette-@64mm.png'
import kaugummi from './scale-objects_0006_kaugummi-@80mm.png'
import kronkorken from './scale-objects_0008_kronkorken-@26mm.png'
import knopf from './scale-objects_0012_knopf-@25mm.png'
import streichhoelzer from './scale-objects_0014_streichhoelzer-@50mm.png'
import playmobil from './scale-objects_0013_playmobilmann-@75mm.png'
import schere from './scale-objects_0001_schere-@220mm.png'
import spitzer from './scale-objects_0009_spitzer-@25mm.png'
import stabilo from './scale-objects_0005_stabilo-@110mm.png'
import strickliesl from './scale-objects_0016_strickliesl-@110.png'
import waescheklammer from './scale-objects_0007_waescheklammer-@75mm.png'
import wuerfel from './scale-objects_0011_wuerfel-@20x36mm.png'

const BigScaleElements = [
  {
    "img":gluehbirne,
    "widthMM": 60,
  },
  {
    "img":kasette,
    "widthMM":100,
  },
  {
    "img":karte,
    "widthMM": 70
  },
  {
    "img":schere,
    "widthMM":70
  }
]


const MediumScaleElements = [
  {
      "img":playmobil,
      "widthMM":25
  },
  {
      "img":stabilo,
      "widthMM":20
  },
  {
      "img":strickliesl,
      "widthMM":18
  },
  {
      "img":waescheklammer,
      "widthMM":10
  },    
  {
      "img":kaugummi,
      "widthMM":15
  }
]

const SmallScaleElements = [
    {
        "img":bueroklammer,
        "widthMM":7.5
    },
    {
        "img":kronkorken,
        "widthMM":16
    },
    {
        "img":spitzer,
        "widthMM":15,
    },
    {
        "img":wuerfel,
        "widthMM":21
    },
    {
      "img":knopf,
      "widthMM":16,
    },
    {
      "img":streichhoelzer,
      "widthMM":35
    }
]

export {
    BigScaleElements,
    MediumScaleElements,
    SmallScaleElements
}