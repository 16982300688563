
import React from 'react'
import styled from 'styled-components'


const PatternInfoWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  transition: opacity 0.3s ease;
  bottom:${props => props.absolutePosition ? "200px" : 0 };
  ${props => props.absolutePosition ? "left:4vw" : "right:0" };
  background-color:white;
  text-align:right;
  padding:5px;
  margin-right:5px;
  z-index:20;
  >p{
    color:#4D4D4D;
    >strong {
      color:black;
      font-family: 'Avenir Next',Arial,Helvetica,sans-serif;
    }
  } 
`

const PatternInfo = ({page, patternCategories = [], absolutePosition, allCategories,show}) => {
  
    const getDisplayName = (category) => {
      /* extracts display name from categories */
      if(category) {
        const matchingCategory = allCategories.find((x) => x.filterName === category)
        return matchingCategory.displayName
      } else return "Ohne Kategorie"
    }
    
    return (
      <PatternInfoWrapper absolutePosition={absolutePosition} show={show}>
        <p>Seite <strong>{parseInt(page)}</strong></p>
        <p>
          Kategorie{patternCategories.length > 1 ? "n " : " "}
          {patternCategories?.map((category, index) => (
            <strong key={index}>{getDisplayName(category)}{index !== patternCategories.length-1 && ", "}</strong>
          ))}
        </p>
      </PatternInfoWrapper>
    );
  }

 export default PatternInfo