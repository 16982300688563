import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import PatternCarousel from '../../components/entdecken/closeup/PatternCarousel'
import ScaleElement from '../../components/entdecken/closeup/ScaleElement'
import PatternInfo from '../../components/entdecken/closeup/PatternInfo'
import FavoritesContext from "../../context/favorites";
import { navigate } from "gatsby"
import { fullPatternImageUrl } from "../../data/dataUrls"
import DownloadButton from '../../components/global/DownloadButton';
import Seo from '../../components/global/Seo';
import PageInfoTitle from '../../components/global/PageInfoTitle';
import PageSubtitle from '../../components/global/PageSubtitle';
import categories from '../../data/categories'
import DottedLine from '../../components/global/DottedLine'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import FavoritenDragOverlay from '../../components/entdecken/FavoritenDragOverlay';
import PixiContext from '../../context/pixiContext'
import {croppedPatternImageUrl} from "../../data/dataUrls"

const CenterWrapper = styled.div`
  position: absolute;
  display:flex;
  width:100%;
  height:100%;
  align-items: center;
  justify-content: center;
  top: 0;
`

const ContentWrapper = styled.div`
  width:100%;
  display:flex;
  position: relative;
`

const PageWrapper = styled.div``

const PatternImageWrapper = styled.div`
  position:relative;
  user-select: none;
  cursor: ${props => props.zoomMode ? `zoom-out` : `zoom-in`};
  height:100%;

`

const PatternImage = styled.img`
  display: block; 
  width:${props => props.zoomMode ? `100vw` : `${props?.width}px`};
  height:${props => props.zoomMode ? `100%` : `${props?.height}px`};
  transition: all 0.5s ease;
  `

const CarouselWrapper =  styled.div`
  position: absolute;
  height:85px;
  width:100%;
  z-index:20;
  display:flex;
  justify-content: space-between;
  align-items: center;
  bottom:50px;
  background-color:white;
  overflow:visible;
`

const SidesWrapper = styled.div`
  width:100%;
  position: relative;
`

const DownloadWrapper = styled.div`
  z-index:9999;
  position:absolute;
  transition: opacity 0.3s ease;
  right:0;
  top:${props => props.overflowing ? "60px" : "-27px" };
  right:${props => props.overflowing ? "100px" : "-27px" };
`

const CloseButtonWrapper = styled.div`
  z-index:100;
  cursor:pointer;
  position:absolute;
  background-color:white;
  top:60px;
  right:20px;
  width:57px;
  height:57px;
  display:grid;
  place-items: center;
  font-size: 30px;
  >svg{
    width: 100%;
    height: 100%;
    stroke:black;
    stroke-width:2;
    &:hover{
      stroke-width:2.5;
      transform: scale(1.1);
    }
  }
`

const DateWrapper = styled.div`
  position: relative;
  margin-left:4vw; 
  z-index:199;
  margin-top:20px;
  display:inline-flex;
  padding: 10px;
  align-items: center;
  font-family:'Avenir Next',sans-serif;
  background-color:white;
`



const CloseButton = () => {
  return (
    <CloseButtonWrapper onClick={() => navigate("/entdecken/")}>  
      <svg viewBox="0 0 40 40">
        <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
      </svg>
    </CloseButtonWrapper>
  )
}




const Closeup = (props) => {
  const { favorites } = useContext(FavoritesContext);
  const { allData, annotationData } = useContext(PixiContext);
  const [displayTooltip, setDisplayTooltip] = useState(false)
  const { height, width } = useWindowDimensions();
  const [draggingImage, setDraggingImage] = useState(false)
  const [zoomMode, setZoomMode ] = useState(false)
  const [currentPattern, setCurrentPattern] = useState(0)

  useEffect(() => {
    const checkForOverflow = (width,height) => {
      let x = false;
      let y = false;
      if(width >= window.innerWidth*0.8) x = true; 
      if(height >= window.innerHeight*0.8) y = true; 
      return {x,y}
    }

    const calcPixelWidth = (widthMM, heightMM) => {
      const pixelPerCm = 40
      const widthCM= parseInt(widthMM)/10
      const heightCM= parseInt(heightMM)/10
      return { 
        pxWidth:widthCM * pixelPerCm,
        pxHeight:heightCM * pixelPerCm 
      }
  
    }
    setZoomMode(false) // reset zoom mode each time the data changes
    if(allData.length > 0 ){
      const mediaId = props.location.search.slice(9)
      const matchingPattern = allData.find(data => data.data.mediaID === mediaId)
      if(typeof(matchingPattern) !== 'undefined'){
        const {pxWidth, pxHeight} = calcPixelWidth(matchingPattern?.data?.sizeMM?.w,matchingPattern?.data?.sizeMM?.h)
        const overflowing = checkForOverflow(pxWidth,pxHeight)
        const loadedPatternData = {
          ...matchingPattern?.data,
          imgSrc: `${fullPatternImageUrl}${matchingPattern?.data.mediaID}`,
          pxWidth,
          pxHeight,
          overflowing
        }
        setCurrentPattern(loadedPatternData)
      }
    }
  },[props.location.search,allData])
  
  useEffect(() => {
    // preload draging image to ensure it beeing there when in case of dragstart
    if(currentPattern !== 0) {
      const dragIcon = document.createElement('img');
      dragIcon.src = `${croppedPatternImageUrl}64/${currentPattern.mediaID}`;
      dragIcon.width = "41";
      dragIcon.height = "41";
      setDraggingImage(dragIcon);
    }
  },[currentPattern])
 

  const handleDownload = () => {
    fetch(currentPattern.imgSrc, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `timbayern_calico_${props.location.search.slice(9)}`); //or any other extension
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }


  const overflowing = currentPattern?.overflowing?.x || currentPattern?.overflowing?.y  ||  zoomMode
  

  return (
    <> 
    <Seo/>
    <PageSubtitle show="true">{`Muster aus Buch ${currentPattern?.inventoryNumber?.slice(3)}`}</PageSubtitle>
    <DateWrapper><PageInfoTitle >{`${currentPattern?.date?.from} ⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ ${currentPattern?.date?.to}`}</PageInfoTitle></DateWrapper>
    <ScaleElement 
      show={zoomMode}
      pxWidth={currentPattern?.pxWidth}
      pxHeight={currentPattern?.pxHeight}
    />
    <PageWrapper>
      <FavoritenDragOverlay />
      {(displayTooltip && favorites.length < 1 ) && 
        <DottedLine
          start={{x:width/2,y:height/2}}
          end={{x:width-110,y:height*0.4-170+20+22.5}} //height * top offset favorites + padding favorites + half size of favorite item
          offset={350}
          tooltipp={"Füge das Muster deinen Favoriten hinzu"}
        />
      }
      <CloseButton/>
      {overflowing &&
        <>
          <PatternInfo 
            allCategories={categories} 
            page={currentPattern.page} 
            patternCategories={currentPattern.category} 
            absolutePosition={true}>
          </PatternInfo>
          <DownloadWrapper overflowing={true}>
            <DownloadButton  onDownloadClick={() => handleDownload()} text={"DIESES MUSTER HERUNTERLADEN?"}/>
          </DownloadWrapper>
        </>
      }
      <CenterWrapper>
        <ContentWrapper>
          <SidesWrapper>
          {!overflowing  &&
          <>
            <PatternInfo 
              allCategories={categories} 
              page={currentPattern.page} 
              patternCategories={currentPattern.category} 
              absolutePosition={false}>
            </PatternInfo>
          </>
          }
          </SidesWrapper>
          <PatternImageWrapper
            
            zoomMode={zoomMode} 
            onDragStart={(e) => {
              if(draggingImage !== false){
                e.dataTransfer.setDragImage(draggingImage, -10, -10);
              }
              e.target.style.opacity = "0.1";
              e.dataTransfer.setData('Text', JSON.stringify(currentPattern));
            }}
            onMouseEnter={(e)=> {
              setDisplayTooltip(true)
            }}
            onMouseLeave={(e)=> {
              setDisplayTooltip(false)
            }}
            onDragEnd={(e) => {
              e.target.style.opacity = "1";
            }}
          >
          <PatternImage 
            src={currentPattern?.imgSrc}
            width={currentPattern?.pxWidth}
            height={currentPattern?.pxHeight}
            zoomMode={zoomMode} 
            onClick={() => setZoomMode(zoomMode => !zoomMode)} 
          />
          {!(overflowing) &&
            <DownloadWrapper >
              <DownloadButton  onDownloadClick={() => handleDownload()} text={"DIESES MUSTER HERUNTERLADEN?"}/>
            </DownloadWrapper>
          }
          </PatternImageWrapper>
          <SidesWrapper/>
        </ContentWrapper>
      </CenterWrapper>
      <CarouselWrapper>
        <PatternCarousel 
          annotationData={annotationData} 
          onThumbClick={(thumb) => {
            navigate(`/entdecken/closeup?pattern=${thumb.mediaID}`)  // update urls
          }}
          onIndexChange={(index) =>{
            // TODO this sometimes doesnt fire which prevents updating of all
            const pattern =annotationData[index]
            navigate(`/entdecken/closeup?pattern=${pattern.mediaID}`)  // update urls
          }}
          activeMediaId={currentPattern.mediaID}
        />
      </CarouselWrapper>
    </PageWrapper>
    </>
  );
}

export default Closeup
