import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import {FavoritesWrapper} from '../global/FavoritesWrapper';
import {FavoriteItem} from '../global/Favorites';
import FavoritesContext from "../../context/favorites";

const FavoriteImgWrapper = styled.div`
  z-index:5000;
  user-select: none;   
  width: 100%; 
  height:100%;
  ${props => props.draggingOver && "background-color: #8ce18c36;"}
`

const FavoritenDragOverlay = () => {
  const { favorites, setFavorites } = useContext(FavoritesContext);
  const [draggingOverIndex, setDraggingOverIndex] = useState(false);

  const handleAddToFavorites = (data, index) => {
    const matchingPattern = JSON.parse(data) // get object from string
    const temp = [...favorites] // spread old favorites
    temp[index] = {
        path: `${matchingPattern.mediaID}`,
        sizeX: matchingPattern.sizePX.w,
        sizeY: matchingPattern.sizePX.h,
        entryPointNAK:{
          frame:matchingPattern.posEntryPointsNAK.frame,
          timecode:matchingPattern.posEntryPointsNAK.timecode
        }

      }; // overwrite the right favorite
    setFavorites(temp)
  }


  return (
    <FavoritesWrapper zIndex={299} background={false}>
      {[...Array(12)].map((item, index) => 
        <FavoriteItem  key ={index}>
          <FavoriteImgWrapper
            draggingOver={draggingOverIndex === index}
            draggable="false"
            onDragOver={(e)=> {
              e.preventDefault();
              e.stopPropagation();
              setDraggingOverIndex(index)
            }}
            onDrop={(e)=> {
              e.preventDefault();
              e.stopPropagation();
              const data = e.dataTransfer.getData("Text");
              handleAddToFavorites(data,index)
              setDraggingOverIndex(false)
            }}
            onDragLeave={(e)=> {
              e.preventDefault();
              e.stopPropagation();
              setDraggingOverIndex(false)
            }}
          />
        </FavoriteItem>
      )}
    </FavoritesWrapper>
  )
}

export default FavoritenDragOverlay 